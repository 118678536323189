.main {
  width: 90%;
  padding: 0px 10px;
  margin: 0px auto;
  margin-top: 1rem;
}

.greet-main {
  max-width: 1200px;
  margin: 0px auto;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.greeting-main {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.greeting-text-div {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  margin-top: 110px;
  font-size: 70px;
  line-height: 5px;
}

.greeting-nickname {
  font-size: 30px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
  margin-right: 40px;
  font-family: "Google Sans Medium";
}
.greeting-image-div {
  display: flex;
  width: fit-content;
  height: 550px;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;
  align-items: center;
}
.greeting-image-div img {
  margin-bottom: 50px;
  width: 110%;
  height: 130%;
  object-fit: cover;
}

.button {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  padding: 15px 15px;
  border-radius: 4px;
  border-width: 0px;
  width: 200px;
  height: 50px;
  font-weight: bold;
  font-family: "Google Sans Regular";
  font-size: 17px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 8px;
}
.portfolio-repo-btn-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 12px;
  gap: 0.4rem;
}
/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
  .greet-main {
    max-width: 900px;
  }
}

@media (max-width: 990px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    flex-direction: column-reverse;
    margin-bottom: 5rem;
  }
  .greeting-image-div {
    align-items: flex-end;
    height: 370px;
  }
  .greeting-image-div img {
    width: 90%;
    height: 120%;
  }
  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
  }
  .greeting-text-div {
    width: 85%;
  }
}
@media (max-width: 425px) {
  .greeting-image-div img {
    width: 90%;
    height: 150%;
  }
  .greeting-image-div {
    margin-top: 4rem;
    height: 350px;
  }
  .greeting-text {
    margin-top: -10px;
  }
}

@media (min-width: 426px) and (max-width: 990px) {
  .greeting-image-div img {
    width: 90%;
    height: 150%;
  }
  .greeting-image-div {
    align-items: center;
    height: 480px;
  }
}
