.contact-main {
  width: 100%;
}

.basic-contact {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2rem;
}

.contact-heading-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.contact-heading-img-div {
  display: flex;
  width: fit-content;
  height: 550px;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;
  align-items: center;
}

.contact-heading-text-div {
  text-align: center;
}

.address-heading-text-div {
  text-align: center;
}
.contact-heading-img-div {
  display: flex;
  width: 80%;
  height: 550px;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 3.5rem;
  align-items: center;
}
.contact-heading-img-div img {
  width: 100%;
  height: 140%;
  object-fit: contain;
  border: none;
  box-shadow: none;
}

.contact-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
}

.contact-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.general-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.general-btn {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  padding: 15px 15px;
  margin-top: 25px;
  border-radius: 4px;
  border-width: 0px;
  width: 200px;
  height: 50px;
  font-weight: bold;
  font-family: "Google Sans Regular";
  font-size: 17px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@media (max-width: 1380px) {
  .contact-heading-text {
    font-size: 50px;
  }
}

@media (max-width: 990px) {
  .contact-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .contact-heading-div {
    flex-direction: column;
  }

  .contact-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
  .contact-heading-img-div {
    align-items: center;
    height: 300px;
    justify-content: center;
  }
  .contact-heading-img-div img {
    width: 90%;
    height: 120%;
  }
}
@media (max-width: 426px) {
  .contact-heading-img-div {
    height: 260px;
    margin-top: 1rem;
  }
  .contact-heading-img-div img {
    width: 90%;
    height: 170%;
  }
}
@media (min-width: 426px) and (max-width: 990px) {
  .contact-heading-img-div img {
    width: 90%;
    height: 140%;
  }
  .contact-heading-img-div {
    height: 500px;
  }
}
