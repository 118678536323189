.cert-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 30px;
  flex: 0 0 30%;
  transition: all 0.2s ease-in-out;
  height: 285px;
  cursor: pointer;
}

.cert-header {
  margin: 0px;
  border-radius: 5px 5px 0px 0px;
}

.logo_img {
  margin: 3px;
  width: 100%;
  height: 100%;
}

.cert-body {
  margin: 0px;
}

.cert-body-title {
  font-size: 16px;
  margin: 10px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.cert-body-subtitle {
  font-family: "Google Sans Regular";
  font-size: 18px;
  color: #808080;
  margin: 10px;
}

.content .cert-header {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content .cert-header img {
}
.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  font-family: "Google Sans Regular";
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.fadeIn-top {
  top: 20%;
}

@media (max-width: 768px) {
  .cert-card {
    width: 100%;
    height: 260px;
  }
}
