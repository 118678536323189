.skills-header {
  font-size: 40px;
  line-height: 1.1;
  text-align: center;
}

.skills-body-div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  margin: 20px auto;
}

@media (max-width: 1380px) {
  .skills-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .skills-body-div {
    flex-direction: column;
    width: 95%;
  }
  .skills-header {
    font-size: 30px;
  }
}
