.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
}

.footer-div {
  margin-top: 8rem;
}
@media (max-width: 500px) {
  .footer-div {
    margin-top: 8rem;
  }
}
