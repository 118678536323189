.skill-card {
  width: 30%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
  flex: 0 0 30%;
  transition: all 0.2s ease-in-out;
}

.skill-header {
  margin: 0px;
  border-radius: 5px 5px 0px 0px;
}

.logo_img {
  margin: 3px;
  width: auto;
  height: 100px;
}

.skill-body {
  margin: 0px;
}

.skill-body-title {
  font-family: "Google Sans Regular";
  font-size: 20px;
  margin: 10px;
  padding: 0.5rem;
}

.skill-body-subtitle {
  font-family: "Google Sans Regular";
  font-size: 18px;
  color: #808080;
  margin: 10px;
}

.content {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

@media (max-width: 768px) {
  .skill-card {
    width: 100%;
  }
}
