.skills-main-div {
  display: flex;
  gap: 0.5rem;
}

.skills-header {
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.skills-text {
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.skills-text-div {
  margin-left: 20px;
  margin-top: 80px;
  font-family: "Google Sans Regular";
}

.skills-main-div > * {
  flex: 0.9;
}
.skills-image-div {
  display: flex;
  width: fit-content;
  height: 600px;
  justify-content: flex-start;
  flex-direction: column;
}
.skills-image-div img {
  width: 100%;
  height: 140%;
  object-fit: contain;
}
.skills-image-div > * {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  font-family: "Google Sans Regular";
}
.skillSentence {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: fit-content;
}

.skillSentence p span {
  display: flex;
  align-items: center;
  text-align: justify;
  white-space: nowrap;
}
.my-skills:nth-child(1) .top-title {
  display: none;
}
/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
  .skills-header {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .skills-header {
    font-size: 30px;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column-reverse;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    order: 2;
  }
  .skills-main-div:nth-child(1) .skills-image-div {
    margin-top: -80px;
  }
  .skills-image-div img {
    height: 140%;
    width: 100%;
  }
  .skills-image-div > * {
    margin-top: 0px;
  }
  .skills-main-div:nth-child(1) .skills-text-div {
    margin-top: -150px;
    justify-content: flex-start;
  }
  .my-skills:nth-child(1) .top-title {
    display: none;
    height: 0;
  }
}
